<template>
  <div class="animated fadeIn">
    <b-form
      @submit.prevent="onSubmit"
    >
      <h4 class="mb-3">
        Добавление транспортного средства
      </h4>
      <p>Вам необходимо заполнить доступные поля, чтобы система смогла произвести проверку на наличие транспортного средства.</p>
      <div class="form-sections">
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Свидетельство о регистрации автомобиля(СТС)
          </h5>
          <b-row>
            <b-col
              md="6"
              class="d-flex"
            >
              <b-form-group
                id="STSInputGroup2"
                label="Серия и номер СТС"
                label-for="sts"
                class="required w-100"
              >
                <b-form-input
                  id="sts"
                  v-model.trim="$v.form.STS.$model"
                  type="text"
                  name="sts"
                  :state="chkState('STS')"
                  aria-describedby="input1LiveFeedback2"
                  autocomplete="sts"
                  @keyup="chkSTSfield($event)"
                  @focus="rememberPreviousValue($v.form.STS.$model, 'STS')"
                  @blur="checkVehicle($v.form.STS.$model, 'STS')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback2">
                  <span v-if="$v.form.STS.required === false">Это поле обязательно для заполнения</span>
                  <span v-if="$v.form.STS.noWhiteSpace === false">В поле не должно быть пробелов</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="d-flex"
            >
              <b-form-group
                id="registrationNumberInputGroup5"
                label="Государственный регистрационный номер (ГРН)"
                label-for="registration-number"
                class="required w-100"
              >
                <b-form-input
                  id="registration-number"
                  v-model.trim="$v.form.registrationNumber.$model"
                  type="text"
                  name="registration-number"
                  :state="chkState('registrationNumber')"
                  aria-describedby="input1LiveFeedback5"
                  autocomplete="registration-number"
                  @focus="rememberPreviousValue($v.form.registrationNumber.$model, 'registrationNumber')"
                  @input="checkVehicle($v.form.registrationNumber.$model, 'registrationNumber')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback5">
                  <span v-if="$v.form.registrationNumber.isTsogr === false">Поле принимает типы: А007АА777, АА000177 (если гос.номер иностранный установите галочку «Иностранный номер»)</span>
                  <span v-if="$v.form.registrationNumber.required === false">Поле обязательно для заполнения</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              class="d-flex"
            >
              <b-form-group
                id="stsScreenInputGroup3"
                label="Лицевая сторона СТС"
                label-for="sts-screen"
                class="required w-100"
              >
                <b-form-file
                  id="sts-screen"
                  v-model.trim="$v.form.stsScreen.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('stsScreen')"
                  name="sts-screen"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder=""
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  :disabled="!checkVehicleDone"
                  @input="uploadFile('stsScreen')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback3">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.stsScreen && form.stsScreen.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.stsScreen.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.stsScreen.file)"
                  >{{ form.stsScreen.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              class="d-flex"
            >
              <b-form-group
                id="stsScreenInputGroup4"
                label="Обратная сторона СТС"
                label-for="sts-screen-back"
                class="required w-100"
              >
                <b-form-file
                  id="sts-screen-back"
                  v-model.trim="$v.form.stsScreenBack.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('stsScreenBack')"
                  name="sts-screen"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder=""
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  :disabled="!checkVehicleDone"
                  @input="uploadFile('stsScreenBack')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback4">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.stsScreenBack.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.stsScreenBack.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.stsScreenBack.file)"
                  >{{ form.stsScreenBack.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по транспортному средству
          </h5>
          <b-row>
            <b-col
            class="col-md-3">
              <b-form-group>
                <b-form-checkbox
                  v-model="$v.form.foreignRegistrationNumber.$model"
                  @change="() => {if($v.form.registrationNumber.$model) checkVehicle($v.form.registrationNumber.$model, 'registrationNumber')}"
                >
                  Иностранное ТС
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
            class="col-md-9 d-flex"
            >
            <b-form-group 
                id="isMissingModel"
                class="mr-4"
                >
                <b-form-checkbox
                  id="is-missing-model"
                  v-model="isMissingModel"
                  name="is-Missing-Model"
                  @change="clearUnrelevantData"
                  description="Если нет нужной марки ТС, напишите её вручную"
                >
                  Нет нужной марки ТС
                </b-form-checkbox>
              </b-form-group>
              <p class="text-muted">
                <small>* Если не найдена нужная марка, то создание ТС возможно только через сотрудника ПЭК. <br>  
                        &#8291; &#8291; Для этого нажмите на кнопку "Отправить письмо с данными в ПЭК".
                </small>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="8"
              class="d-flex"
            >
              <b-form-group
              v-if="!isMissingModel"
                id="vehicleMakeInputGroup4"
                v-b-popover.v-danger="{ content: 'Пожалуйста, заполните поле', placement: 'topleft'}"
                class="form-group-el-select required w-50"
                label="Марка ТС"
                label-for="vehicle-make"
                :state="chkState('vehicleMake')"
                :invalid-feedback="'ГРН не соответствует Типу ТС'"
              >
              
                <el-select
                  v-model="$v.form.vehicleMake.value.$model"
                  class="form-control"
                  :class="{'is-valid-error': $v.form.vehicleMake.value.$error, 'is-valid-success': $v.form.vehicleMake.value.required}"
                  :disabled="!checkVehicleDone || existingVehicle"
                  name="vehicle-make"
                  placeholder="Начните вводить название марки"
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="vehicleMakeRemote"
                  clearable
                  :loading="loading"
                  :state="chkState('vehicleMake') "
                  @change="onVehicleMakeChange(form.vehicleMake.value)"
                >
                  <el-option
                    v-for="item in form.vehicleMake.options"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
              <b-form-group
                v-if="isMissingModel"
                id="customVehicleModel"
                label="Марка ТС"
                :state="chkState('customVehicleModel')"
                label-for="custom-vehicle-model"
                class="required w-50"
              >
                <b-form-input
                  id="custom-vehicle-model"
                  v-model.trim="form.customVehicleModel"
                  :class="{'is-valid-error': this.form.customVehicleModel.$error, 'is-valid-success': this.form.customVehicleModel.required}"
                  type="text"
                  name="customVehicleModel"
                  :state="chkState('customVehicleModel')"
                  aria-describedby="customVehicleModel"
                  autocomplete="customVehicleModel"
                  maxlength = "50"
                  placeholder="Введите название марки (до 50 символов)"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="d-flex"
            >
              <b-form-group
                id="vehicleTypeGroup10"
                v-b-popover.v-danger="{ content: 'Пожалуйста, заполните поле', placement: 'topleft'}"
                class="form-group-el-select w-100"
                label="Тип ТС"
                label-for="vehicle-type"
                :invalid-feedback="'Поле обязательно для заполнения'"
                :state="chkState('vehicleType')"
              >
                <el-select
                  id="vehicle-type"
                  v-model="$v.form.vehicleType.id.$model"
                  class="form-control"
                  :class="{'is-valid-error': $v.form.vehicleType.$error, 'is-valid-success': $v.form.vehicleType.id.required}"
                  name="vehicle-type"
                  clearable
                  :loading="loading"
                  :disabled="!isMissingModel"
                  :state="chkState('vehicleType')"
                >
                  <el-option
                    v-for="item in vehicleTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-form-group 
                id="isWrongDimensions"
                v-if="form.vehicleType.id !==vehicleTypeWithOutBodyId"
                >
                <b-form-checkbox
                  id="is-wrong-dimensions"
                  v-model="isWrongDimensions"
                  name="is-Wrong-Dimensions"
                  description="Если не подходят габариты ТС, заполните их вручную"
                >
                  Не подходят габариты ТС
                </b-form-checkbox>
              </b-form-group>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="4"
            >
              <b-form-group
                id="bodyVolumeInputGroup6"
                label="Объем, м³"
                label-for="body-volume"
              >
                <b-form-input
                  id="body-volume"
                  v-model.trim="$v.form.bodyVolume.$model"
                  type="number"
                  step="0.01"
                  name="body-volume"
                  :disabled="!isEnabledTypes"
                  autocomplete="body-volume"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyWidthInputGroup8"
                label="Ширина кузова"
                label-for="body-width"
                :class="getRequiredClass()"
              >
                <b-form-input
                  id="body-width"
                  v-model.trim="$v.form.bodyWidth.$model"
                  type="number"
                  step="0.01"
                  name="body-width"
                  :disabled="!isEnabledTypes"
                  autocomplete="body-width"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyHeightInputGroup7"
                label="Высота кузова"
                label-for="body-height"
                :class="getRequiredClass()"
              >
                <b-form-input
                  id="body-height"
                  v-model.trim="$v.form.bodyHeight.$model"
                  type="number"
                  step="0.01"
                  name="body-height"
                  :disabled="!isEnabledTypes"
                  autocomplete="body-height"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyDepthInputGroup9"
                label="Глубина кузова"
                label-for="body-depth"
                :class="getRequiredClass()"
              >
                <b-form-input
                  id="body-depth"
                  v-model.trim="$v.form.bodyDepth.$model"
                  type="number"
                  step="0.01"
                  name="body-depth"
                  :disabled="!isEnabledTypes"
                  autocomplete="body-depth"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="weightAllowedInputGroup6"
                label="Грузоподъемность, т"
                label-for="weight-allowed"
                :class="getRequiredClass()"
              >
                <b-form-input
                  id="weight-allowed"
                  v-model.trim="$v.form.weightAllowed.$model"
                  type="number"
                  step="0.01"
                  name="weight-allowed"
                  :disabled="!isEnabledTypes"
                  autocomplete="weight-allowed"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="6"
              class="d-flex"
            >
              <b-form-group
                id="bodyTypeGroup11"
                v-b-popover.v-danger="{ content: 'Пожалуйста, заполните поле', placement: 'topleft'}"
                class="form-group-el-select required w-100"
                label="Тип кузова"
                label-for="body-type"
                :invalid-feedback="'Это поле обязательно для заполнения'"
                :state="chkState('bodyType')"
              >
                <el-select
                  id="body-type"
                  v-model="$v.form.bodyType.id.$model"
                  class="form-control"
                  :class="{'is-valid-error': $v.form.bodyType.$error, 'is-valid-success': $v.form.bodyType.id.required}"
                  name="body-type"
                  placeholder=""
                  clearable
                  :loading="loading"
                  :state="chkState('bodyType')"
                  :disabled="!checkVehicleDone || existingVehicle || !form.vehicleType.id"
                >
                  <el-option
                    v-for="item in bodyTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Владелец транспортного средства
          </h5>
          <b-row>
            <b-col>
              <b-form-group id="isOwnGroup12">
                <b-form-checkbox
                  id="is-own"
                  v-model="$v.isOwn.$model"
                  name="is-own"
                  description="Отметьте это поле, только в случае точного совпадения Вашей организации с Собственником ТС указанным в СТС"
                  :disabled="existingOwner"
                >
                  Собственное ТС
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!isOwn"
              md="12"
            >
              <b-form-group
                id="contractorInputGroup5"
                v-b-popover.hover.v-info="{ content: 'Для создания ТС укажите владельца ТС', placement: 'top' }"
                class="form-group-el-select"
                label="Владелец"
                label-for="contractor"
                description="Если не найдена нужная организация, то создание ТС возможно только через сотрудника ПЭК, для этого нажмите на кнопку «Отправить письмо с данными в ПЭК»"
              >
                <el-select
                  v-model="owner.value"
                  class="form-control"
                  name="owner"
                  placeholder="Начните вводить название организации"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="contractorRemote"
                  :loading="loading"
                  :disabled="existingOwner"
                >
                  <el-option
                    v-for="item in owner.options"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Водитель выполняющий рейсы на данном ТС<span class="text-danger">*</span>
          </h5>
          <p>Укажите водителя, который будет выполнять рейсы на данном траспортном средстве.</p>
          <div class="custom-fieldset">
            <b-row>
              <b-col md="12">
                <b-form-group
                  id="driver"
                  v-b-popover.v-danger="{ content: 'Пожалуйста, заполните поле', placement: 'topleft'}"
                  class="form-group-el-select"
                  :invalid-feedback="'Это поле обязательно для заполнения.'"
                  :state="chkState('legitDriver')"
                >
                  <el-select
                    v-model="$v.form.legitDriver.$model"
                    clearable
                    placeholder="Укажите водителя"
                    class="form-control"
                    :class="{'is-valid-error': $v.form.legitDriver.$error, 'is-valid-success': $v.form.legitDriver.required}"
                    name="legit-driver"
                    filterable
                    :state="chkState('legitDriver')"
                    :disabled="!checkVehicleDone"
                    :remote="true"
                    :remote-method="contractorsRoutesLegitDriversList"
                    @change="contractorLegitDriverRead(form.legitDriver)"
                  >
                    <el-option
                      v-for="item in legitDrivers"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    />
                  </el-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          class="mr-2"
          :disabled="!checkVehicleDone || loading || isStillDisabledLetter"
          :class="{'loading' : loading}"
        >
          Создать
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="!checkVehicleDone || loading || !isStillDisabledLetter || isEmptyDimensions"
          :class="{'loading' : loading}"
        >
          Отправить письмо с данными в ПЭК
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {validation, isTsogr, noWhiteSpace} from '../../components/mixins/validation';
import {required, minLength, email} from 'vuelidate/lib/validators';
import notifications from '../../components/mixins/notifications';
import {
  contractorsTransportVehicleCreate,
  contractorsTransportVehicleSendToCheck,
  contractorsRoutesLegitDriversList,
  checkContractorsVehicleWithStsAndRegistrationNumber,
  vehiclesModelRead,
  vehicleModelsList,
  vehicleModelsTypesList,
  vehicleModelsBodyTypesList,
  contractorsRelatedOwnersList,
  documentCreate,
  downloadFile,
} from '../../services/api';

export default {
  name: 'TransportVehiclesAdd',
  mixins: [validationMixin, notifications],
  data() {
    return {
      checkVehicleDone: false,
      existingVehicle: false,
      existingOwner: false,
      vehicleTypes: [{value: null, text: ''}],
      bodyTypes: [{value: null, text: ''}],
      vehicleTypeWithOutBodyId: null,
      isOwn: false,
      isMissingModel: false,
      isWrongDimensions: false,
      owner: {
        options: [],
        value: '',
      },
      legitDrivers: [],
      form: {
        STS: '',
        customVehicleModel: '',
        stsScreen: [],
        stsScreenBack: [],
        vehicleMake: {
          options: [],
          value: '',
        },
        registrationNumber: '',
        bodyVolume: '',
        weightAllowed: '',
        bodyHeight: '',
        bodyWidth: '',
        bodyDepth: '',
        brand: '',
        vehicleType: {
          id: null,
        },
        bodyType: {
          id: null,
        },
        foreignRegistrationNumber: false,
        legitDriverId: null,
        legitDriver: '',
      },
      fieldsTriggeringCheckVehicleOldValues: {
        STS: '',
        registrationNumber: '',
      },
      feedBack: 'secondary',
      loading: false,
    };
  },
  computed: {
    isEnabledTypes() {
      if (this.isWrongDimensions) {
        return true;
      } else if (this.isMissingModel) {
        return true;
      } else if (this.isMissingModel && this.isWrongDimensions) {
        return true;
      } else {
        return false;
      }
    },
    isStillDisabledLetter() {
      if (this.isWrongDimensions && this.isOwn) {
        return true;
      } else if (this.isWrongDimensions && (!!this.owner.value === true)) {
        return true;
      } else if (this.isMissingModel && this.isOwn) {
        return true;
      } else if (this.isMissingModel && (!!this.owner.value === true)) {
        return true;
      } else if (this.isOwn){
        return false;
      } else if(!!this.owner.value === true) {
        return true;
      } else if (this.isEnabledTypes){
        return true;
      } else if (!this.isOwn && (!!this.owner.value === false)) {
        return true;
      } else {
        return false;
      }
    },
    isEmptyDimensions() {
      if (this.form.vehicleType.id !== 1) {
        const {
        bodyDepth,
        bodyHeight,
        bodyWidth,
        weightAllowed,
      } = this.form;

      return !bodyDepth || !bodyHeight || !bodyWidth || !weightAllowed;
      } else {
        return false;
      }  
    },
    formStr() {
      const calculationFormData = {};
      calculationFormData.vehicle = {};
      calculationFormData.isOwn = this.isOwn;
      calculationFormData.isMissingModel = this.isMissingModel;
      calculationFormData.isWrongDimensions = this.isWrongDimensions;

      if (this.form.bodyVolume && this.form.bodyVolume !== undefined) {
        calculationFormData.vehicle.bodyVolume = this.form.bodyVolume;
      } else {
        calculationFormData.vehicle.bodyVolume = 0;
      }

      if (this.form.weightAllowed && this.form.weightAllowed !== undefined) {
        calculationFormData.vehicle.weightAllowed = this.form.weightAllowed;
      } else {
        calculationFormData.vehicle.weightAllowed = 0;
      }

      if (this.form.bodyHeight && this.form.bodyHeight !== undefined) {
        calculationFormData.vehicle.bodyHeight = this.form.bodyHeight;
      } else {
        calculationFormData.vehicle.bodyHeight = 0;
      }

      if (this.form.bodyWidth && this.form.bodyWidth !== undefined) {
        calculationFormData.vehicle.bodyWidth = this.form.bodyWidth;
      } else {
        calculationFormData.vehicle.bodyWidth = 0;
      }

      if (this.form.bodyDepth && this.form.bodyDepth !== undefined) {
        calculationFormData.vehicle.bodyDepth = this.form.bodyDepth;
      } else {
        calculationFormData.vehicle.bodyDepth = 0;
      }
      
      if (!this.isMissingModel) {
        calculationFormData.vehicle.name = this.form.brand + ' ' + this.form.registrationNumber;
      } else {
        calculationFormData.vehicle.name = this.form.customVehicleModel + ' ' + this.form.registrationNumber;
      }
      
      calculationFormData.vehicle.registrationNumber = this.form.registrationNumber;
      calculationFormData.vehicle.certificateRegistrationVehicleSeriesNumber = this.form.STS;
      if (this.form.stsScreen && this.form.stsScreen.file) {
        calculationFormData.stsScreen = this.form.stsScreen;
      }
      
      if (this.form.vehicleType.id && this.form.vehicleType.id !== null) {
        calculationFormData.vehicleType = { id: this.form.vehicleType.id };
      }
      if (this.form.stsScreenBack && this.form.stsScreenBack.file) {
        calculationFormData.stsScreenBack = this.form.stsScreenBack;
      }
      if (!this.isMissingModel) {
        if (this.form.vehicleMake.value && this.form.vehicleMake.value !== '') {
          calculationFormData.vehicle.model = {id: this.form.vehicleMake.value};
        }
      }

      if (this.isMissingModel) {
        if (this.form.customVehicleModel && this.form.customVehicleModel !== '') {
          calculationFormData.customVehicleModel = this.form.customVehicleModel;
        }
      }
      if (this.form.bodyType.id !== null) {
        calculationFormData.vehicle.bodyType = {id: this.form.bodyType.id};
      }
      calculationFormData.vehicle.foreignRegistrationNumber = this.form.foreignRegistrationNumber;
      if (!this.isOwn && this.owner.value) {
        calculationFormData.contractorOwner = {id: this.owner.value};
      }
      if (this.legitDriverId !== null) {
        calculationFormData.contractorDriver = {id: this.form.legitDriverId};
      }
      if (this.form.legitDriver !== null) {
        calculationFormData.legitDriver = this.form.legitDriver;
      }
      return calculationFormData;
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations() {
    const t = {
      form: {
        STS: {
          required,
          noWhiteSpace,
        },
        customVehicleModel: '',
        stsScreen: {
          required,
        },
        stsScreenBack: {
          required,
        },
        vehicleMake: '',
        bodyVolume: '',
        weightAllowed: '',
        bodyHeight: '',
        bodyWidth: '',
        bodyDepth: '',
        brand: '',
        vehicleType: {
          id: {required},
        },
        bodyType: {
          id: '',
        },
        foreignRegistrationNumber: '',
        legitDriverId: '',
        legitDriver: {
          required,
        },
      },
      isOwn: '',
      isMissingModel: '',
      isWrongDimensions: '',
    };
    if (!this.isMissingModel) {
      t.form.vehicleMake = {
        isVehicleTypeForOgr: this.isVehicleTypeForOgr,
        value: {required}, 
      };
    }
    if (this.isMissingModel) {
      t.form.customVehicleModel = {
        required,
      };
    }
    if (this.form.foreignRegistrationNumber) {
      t.form.registrationNumber = {
        required,
      };
    } else {
      t.form.registrationNumber = {
        isTsogr,
      };
    }

    if (this.form.vehicleType.id !== this.vehicleTypeWithOutBodyId) {
      t.form.bodyType.id = {
        required,
      };
    }
    return t;
  },
  mounted() {
    this.vehicleModelsTypesList();
    this.vehicleModelsBodyTypesList();
    this.contractorsRoutesLegitDriversList();
    this.contractorRemote('');
  },
  methods: {
    downloadFile,
    clearForm() {
      this.form.STS = '';
      this.form.customVehicleModel = '';
      this.form.stsScreen = [];
      this.form.stsScreenBack = [];
      this.form.vehicleMake = {options: [], value: ''};
      this.form.registrationNumber = '';
      this.form.bodyVolume = '';
      this.form.weightAllowed = '';
      this.form.bodyHeight = '';
      this.form.bodyWidth = '';
      this.form.bodyDepth = '';
      this.form.brand = '';
      this.form.legitDriver = '';
      this.form.vehicleType.id = null;
      this.form.bodyType.id = null;
      this.isOwn = false;
      this.isMissingModel = false;
      this.isWrongDimensions = false;
      this.form.foreignRegistrationNumber = false;
    },
    clearFormBeforeCheckVehicle() {
      this.form.customVehicleModel = '';
      this.form.stsScreen = [];
      this.form.stsScreenBack = [];
      this.form.vehicleMake = {options: [], value: ''};
      this.form.bodyVolume = '';
      this.form.weightAllowed = '';
      this.form.bodyHeight = '';
      this.form.bodyWidth = '';
      this.form.bodyDepth = '';
      this.form.brand = '';
      this.form.legitDriver = '';
      this.form.vehicleType.id = null;
      this.form.bodyType.id = null;
      this.isOwn = false;
      this.isMissingModel = false;
      this.isWrongDimensions = false;
    },
    clearUnrelevantData() {
      if(this.form.registrationNumber){
        this.vehicleModelsList({
                limit: 10,
                registration_number: this.form.registrationNumber,
              });
      }
      
      this.form.customVehicleModel = '';
      this.form.vehicleType.id = null;
      this.form.bodyType.id = null;
      this.form.vehicleMake = {options: [], value: ''};
      this.form.bodyVolume = '';
      this.form.weightAllowed = '';
      this.form.bodyHeight = '';
      this.form.bodyWidth = '';
      this.form.bodyDepth = '';
    },
    rememberPreviousValue(value, input) {
      this.fieldsTriggeringCheckVehicleOldValues[input] = value;
    },
    async checkVehicle(value, input) {
      if (this.fieldsTriggeringCheckVehicleOldValues[input] !== value && this.chkState(input)) {
        this.clearFormBeforeCheckVehicle();
        this.showContractorPopover();
        if (this.form.STS && this.form.registrationNumber) {
          this.loading = true;
          this.checkVehicleDone = false;
          const response = await checkContractorsVehicleWithStsAndRegistrationNumber(this.$store.state.user.ownerId, this.form.STS, this.form.registrationNumber);
          if (response && response.status === 200) {
            if (response.data) {
              this.checkVehicleDone = true;
              this.existingVehicle = true;
              this.showInfo('Данное транспортное средство уже существует');
              this.form.vehicleMake.options = [];
              this.form.vehicleMake.options.push({
                value: response.data.model.id,
                text: response.data.model.name + (response.data.model.containerTransportVehicle ? ' (контейнеровоз)':''),
              });
              this.form.vehicleMake.value = response.data.model.id;
              if (response.data.bodyType) {
                this.form.bodyType.id = response.data.bodyType.id;
              }
              if (response.data.contractorOwner) {
                this.existingOwner = true;
                this.owner.value = response.data.contractorOwner.id;
                this.owner.options = [{
                  value: response.data.contractorOwner.id,
                  text: response.data.contractorOwner.name,
                }];
              }
              this.$nextTick(() => {
                this.getVehicleModelData(response.data.model.id, this.form.registrationNumber);
              });
            } else {
              this.checkVehicleDone = true;
              this.existingVehicle = false;
              this.showInfo('Данное транспортное средство не найдено');
              this.contractorRemote('');
              this.existingOwner = false;
              this.owner.value = null;
              this.vehicleModelsList({
                limit: 10,
                registration_number: this.form.registrationNumber,
              });
            }
          }
          this.loading = false;
        } else if (this.checkVehicleDone) {
          this.checkVehicleDone = false;
        }
      }
    },
    getRequiredClass() {
      return {
        'required': ((this.isMissingModel && this.form.vehicleType.id!== 1) || this.isWrongDimensions) || ((this.isMissingModel && this.form.vehicleType.id!== 1) && this.isWrongDimensions),
      }
    },
    async getVehicleModelData(modelId, registrationNumber) {
      this.loading = true;
      if (modelId) {
        const response = await vehiclesModelRead(modelId, {registration_number: registrationNumber});
        if (response && response.status === 200) {
          this.form.bodyVolume = response.data.bodyVolume;
          this.form.weightAllowed = response.data.loadCapacity;
          this.form.bodyHeight = response.data.bodyHeight;
          this.form.bodyWidth = response.data.bodyWidth;
          this.form.bodyDepth = response.data.bodyDepth;
          this.form.brand = response.data.brand;
          this.form.vehicleType.id = response.data.type.id;
        }
      }
      this.loading = false;
    },
    async contractorsRoutesLegitDriversList(fio) {
      this.loading = true;
      const params = {limit: 100};
      if (fio) {
        params.fio = fio;
      }
      const response = await contractorsRoutesLegitDriversList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.legitDrivers = response.data.items.map((item) => {
          let text = null;
          if (item.driver.patronymic !== undefined) {
            text = `${item.driver.surname} ${item.driver.name} ${item.driver.patronymic}`;
          } else {
            text = `${item.driver.surname} ${item.driver.name}`;
          }
          return {value: item.id, text: text};
        });
      }
      this.loading = false;
    },
    contractorLegitDriverRead(legitDriverId) {
      this.form.legitDriverId = legitDriverId;
    },
    onVehicleMakeChange(value) {
      this.getVehicleModelData(value, this.form.registrationNumber);
    },
    async vehicleModelsList(params) {
      this.loading = true;
      const response = await vehicleModelsList(params);
      if (response && response.status === 200) {
        this.form.vehicleMake.options = response.data.items.map((item) => {
          return {value: item.id, text: item.name + (item.containerTransportVehicle ? ' (контейнеровоз)':'')};
        });
      }
      this.loading = false;
    },
    async vehicleModelsTypesList(params) {
      this.loading = true;
      const response = await vehicleModelsTypesList(params);
      if (response && response.status === 200) {
        this.vehicleTypes = this.vehicleTypes.concat(
          response.data.items.map((item) => {
            if (item.name === 'Тягач') {
              this.vehicleTypeWithOutBodyId = item.id;
            }
            return {value: item.id, text: item.name};
          }),
        );
      }
      this.loading = false;
    },
    async vehicleModelsBodyTypesList() {
      this.loading = true;
      const response = await vehicleModelsBodyTypesList({
        for_delivery: false,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.bodyTypes = this.bodyTypes.concat(
          response.data.items.map((item) => {
            return {value: item.id, text: item.name};
          }),
        );
      }
      this.loading = false;
    },
    vehicleMakeRemote(query) {
      if (query !== '') {
        this.vehicleModelsList({
          query: query,
          limit: 100,
        });
      } else {
        this.vehicleModelsList({
          limit: 10,
        });
      }
    },
    async contractorRemote(query) {
      this.loading = true;
      const response = await contractorsRelatedOwnersList(this.$store.state.user.ownerId, {
        name: query,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.owner.options = response.data.items.map((item) => {
          return {value: item.owner.id, text: item.owner.name};
        });
      }
      this.loading = false;
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await contractorsTransportVehicleCreate(this.$store.state.user.ownerId, this.formStr);
        if (response && response.status === 201) {
          if (response.data.vehicle && typeof response.data.status === 'undefined') {
            if (!response.data.vehicle.status) {
              this.showSuccess('Транспортное средство создано');
              this.$bvModal
                .msgBoxConfirm('Вы успешно создали Транспортное средство, хотите отправить его на проверку логисту?', {
                  okTitle: 'Да',
                  cancelTitle: 'Нет',
                })
                .then((confirm) => {
                  if (confirm) {
                    this.contractorsTransportVehicleSendToCheck(response.data.id);
                  } else {
                    this.$router.push({path: `/contractors/id${this.$store.state.user.ownerId}/vehicle/id${response.data.id}`});
                  }
                });
            } else {
              this.showSuccess('Транспортное средство создано');
              this.$router.push({path: `/contractors/id${this.$store.state.user.ownerId}/vehicle/id${response.data.id}`});
            }
          } else {
            this.showWarn('Письмо отправлено. Создался черновик транспортного средства (повторное создание не требуется).');
            this.$router.push({path: '/transport-vehicles'});
          }
        }
        this.loading = false;
      } else {
        this.showWarn('Вы заполнили не все нужные поля. Пожалуйста, заполните обязательные поля отмеченные красным.');
      }
    },
    async contractorsTransportVehicleSendToCheck(transportVehiclesId) {
      this.loading = true;
      const response = await contractorsTransportVehicleSendToCheck(this.$store.state.user.ownerId, transportVehiclesId);
      if (response && response.status === 200) {
        this.showSuccess('Транспортное средство отправлено на верификацию в СБ');
        this.$router.push({path: `/contractors/id${this.$store.state.user.ownerId}/vehicle/id${transportVehiclesId}`});
      }
      this.loading = false;
    },
    showContractorPopover() {
      this.$root.$emit('bv::show::popover', 'contractorInputGroup5');
    },
    chkSTSfield(e) {
      if (this.form.foreignRegistrationNumber) {
        this.$v.form.STS.$model = e.currentTarget.value.replace(/[^0-9a-z_\-]+/gi, '').toUpperCase();
      } else {
        this.$v.form.STS.$model = e.currentTarget.value.replace(/[^0-9а-я_\-]+/gi, '').toUpperCase();
      }
    },
    chkState(val, subval = null) {
      let field = this.$v.form[val];
      if (subval) {
        field = field[subval];
      }
      if (this.isMissingModel) {
        if (!this.$v.form.vehicleMake?.$error) {
          this.$root.$emit('bv::hide::popover', 'vehicleMakeInputGroup4');
        }
      }
      if (!this.$v.form.vehicleType.$error) {
        this.$root.$emit('bv::hide::popover', 'vehicleTypeGroup10');
      }
      if (!this.$v.form.bodyType.$error) {
        this.$root.$emit('bv::hide::popover', 'bodyTypeGroup11');
      }
      if (!this.$v.form.legitDriver.$error) {
        this.$root.$emit('bv::hide::popover', 'driver');
      }
      if (!field?.$dirty) {
        return null;
      } else return !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      if (this.isMissingModel) {
        if (this.$v.form.vehicleMake?.$error) {
        this.$root.$emit('bv::show::popover', 'vehicleMakeInputGroup4');
        }
      }
      if (this.$v.form.vehicleType.$error) {
        this.$root.$emit('bv::show::popover', 'vehicleTypeGroup10');
      }
      if (this.$v.form.bodyType.$error) {
        this.$root.$emit('bv::show::popover', 'bodyTypeGroup11');
      }
      if (this.$v.form.legitDriver.$error) {
        this.$root.$emit('bv::show::popover', 'driver');
      }
      if (this.isValid) {
        return this.isValid;
      }
    },
    async documentCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.form[key] = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    uploadFile(key) {
      const file = this.form[key];
      if (file) {
        if (file.size >= 15 * 1024 * 1024) {
          this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
        } else {
          this.getBase64(file).then((data) => {
            const upload = {
              base64file: data,
              originalName: file.name,
            };
            this.documentCreate(key, upload);
          });
        }
      }
    },
    isVehicleTypeForOgr(vt, vm) {
      const bodyType = this.vehicleTypes.find((type)=>{
        return type.value === this.form.vehicleType.id;
      });
      if (this.form.foreignRegistrationNumber) {
        return true;
      }
      if (bodyType.text === 'Прицеп' || bodyType.text === 'Полуприцеп') {
        if (/^[а-яА-Яё\-\s]{2}(?!0{4})[0-9]{4}[0-9]{2,3}$/.test(this.form.registrationNumber)) {
          return true;
        }
      } else {
        if (/^[а-яА-Яё\-\s]{1}(?!0{3})[0-9]{3}[а-яА-Яё\-\s]{2}[0-9]{2,3}$/.test(this.form.registrationNumber)) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.text-muted {
  font-size: 16px;
}

.is-valid-error {
  border: 1px solid red !important;
  height: 100%;
}

.is-valid-success {
  border: 1px solid var(--success) !important;
  height: 100%;
}

.invalid-feedback {
  font-size: 15px;
}

.popover-body {
  font-size: 15px;
}
</style>
